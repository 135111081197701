import Vue from "vue";
import "./assets/styles/main.css";
import store from "./store";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import { i18n } from "./i18n";
import { Trans } from "./plugins/Translation";
import VueExcelEditor from "vue-excel-editor";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import JsonExcel from "vue-json-excel";
// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase";

Vue.use(VueSweetalert2);
Vue.component("downloadExcel", JsonExcel);
const env = process.env.NODE_ENV || "development";
let firebaseConfig;
if (env === "production") {
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  firebaseConfig = {
    apiKey: "AIzaSyCXTheqdw2kdKX0YDcUiu67R1xEaZcHcGY",
    authDomain: "schoolbase.firebaseapp.com",
    projectId: "schoolbase",
    storageBucket: "schoolbase.appspot.com",
    messagingSenderId: "1037312867658",
    appId: "1:1037312867658:web:991649d7464042eeab15b3",
    measurementId: "G-8541JY1M4L",
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyCXTheqdw2kdKX0YDcUiu67R1xEaZcHcGY",
    authDomain: "schoolbase.firebaseapp.com",
    projectId: "schoolbase",
    storageBucket: "schoolbase.appspot.com",
    messagingSenderId: "1037312867658",
    appId: "1:1037312867658:web:991649d7464042eeab15b3",
    measurementId: "G-8541JY1M4L",
  };
}
firebase.initializeApp(firebaseConfig);

Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans);

Vue.config.productionTip = false;

// Vue.use(GridInstaller)
// Vue.use(DataSourceInstaller)

Vue.use(VueExcelEditor);

new Vue({
  vuetify,
  i18n,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
