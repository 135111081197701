<template>
  <div id="app">
    <v-app id="inspire" style="background-color: #ffffff">
      <v-main>
        <router-view />
      </v-main>
    </v-app>
  </div>
</template>

<script>
export default {
  name: "App",
  data: () => ({}),
  props: {},
  methods: {},
  components: {},
  created() {
    // Initial Institute
    // instituteHandler.init()
  }
};
</script>
