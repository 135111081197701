const getDisplayLoader = state => {
  return state.displayLoader;
};

const getLoggedUser = state => {
  let user = state.loggedUser;
  if (user) {
    user = JSON.parse(user);
  }
  return user;
  // window.console.log(user)
};
const getLoggedMajor = state => {
  let major = state.loggedMajor;
  if (major) {
    major = JSON.parse(major);
  }
  return major;
  // window.console.log(user)
};
const getToken = state => {
  let token = state.loggedToken;
  if (token) {
    token = JSON.parse(token);
  }
  return token;
};
export default {
  getDisplayLoader,
  getLoggedUser,
  getLoggedMajor,
  getToken
};
