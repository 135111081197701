import Vue from "vue";
import VueRouter from "vue-router";
import { Trans } from "@/plugins/Translation";
import { i18n } from "../i18n";
import store from "../store";
function load(component) {
  // '@' is aliased to src/components
  return () => import(`@/views/${component}.vue`);
}
function loadAdmin(component) {
  // '@' is aliased to src/components
  return () => import(`@/views/admin/${component}.vue`);
}
function loadStudent(component) {
  // '@' is aliased to src/components
  return () => import(`@/views/student/${component}.vue`);
}
function loadLecturer(component) {
  // '@' is aliased to src/components
  return () => import(`@/views/lecturer/${component}.vue`);
}
function loadMajorAdmin(component) {
  // '@' is aliased to src/components
  return () => import(`@/views/major-admin/${component}.vue`);
}
function loadTsc(component) {
  // '@' is aliased to src/components
  return () => import(`@/views/tsc/${component}.vue`);
}
function loadTopManager(component) {
  // '@' is aliased to src/components
  return () => import(`@/views/top-manager/${component}.vue`);
}

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
const routes = [
  {
    path: "/:locale",
    beforeEnter: Trans.routeMiddleware,
    component: {
      template: "<keep-alive><router-view></router-view></keep-alive>"
    },
    children: [
      {
        path: "/",
        component: load("Index"),
        name: "Index",
        meta: {
          requireAuth: true,
          guest: false
        },

        children: [
          {
            path: "super-adminn",
            component: loadAdmin("Dashboard"),
            name: "AdminDashboard",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: true,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: false,
              isTcAdmin: false,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path: "super-admin/manage/user",
            component: loadAdmin("ManageUser"),
            name: "ManageUser",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: true,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: false,
              isTcAdmin: false,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path: "super-admin/manage/major",
            component: loadAdmin("ManageMajor"),
            name: "ManageMajor",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: true,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: false,
              isTcAdmin: false,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path: "super-admin/manage/lecturer",
            component: loadAdmin("ManageLecturer"),
            name: "ManageLecturer",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: true,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: false,
              isTcAdmin: false,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path: "super-admin/manage/student",
            component: loadAdmin("ManageStudent"),
            name: "ManageStudent",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: true,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: false,
              isTcAdmin: false,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path: "super-admin/list/role",
            component: loadAdmin("ManageRole"),
            name: "ManageRole",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: true,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: false,
              isTcAdmin: false,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path: "super-admin/manage/permission",
            component: loadAdmin("ManagePermission"),
            name: "ManagePermission",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: true,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: false,
              isTcAdmin: false,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path: "top-managerr",
            component: loadTopManager("TopManagerDashboard"),
            name: "TopManagerDashboard",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: false,
              isTcAdmin: false,
              isTopManager: true,
              roleChoose: false
            }
          },
          {
            path: "top-managerr/gen/:id&:name_en",
            component: loadTopManager("TopManagerGen"),
            name: "TopManagerGen",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: false,
              isTcAdmin: false,
              isTopManager: true,
              roleChoose: false
            }
          },

          {
            path: "top-managerr/gen/annual/:id&:name_en&:major_id",
            component: loadTopManager("TopManagerAnnual"),
            name: "TopManagerAnnual",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: false,
              isTcAdmin: false,
              isTopManager: true,
              roleChoose: false
            }
          },
          {
            path: "top-managerr/gen/annual/score/:session_ids&:annual_id",
            component: loadTopManager("TopManagerAnnualScore"),
            name: "TopManagerAnnualScore",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: false,
              isTcAdmin: false,
              isTopManager: true,
              roleChoose: false
            }
          },

          {
            path:
              "top-managerr/gen/annual/score/transcript/:student_id&:annual_id",
            component: loadTopManager("TopManagerTranscript"),
            name: "TopManagerTranscript",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: false,
              isTcAdmin: false,
              isTopManager: true,
              roleChoose: false
            }
          },

          {
            path: "major-adminn",
            component: loadMajorAdmin("Dashboard"),
            name: "MajorAdminDashboard",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: true,
              isTcAdmin: false,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path: "major-admin/manage/generations",
            component: loadMajorAdmin("ManageMajorGen"),
            name: "ManageMajorGen",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: true,
              isTcAdmin: false,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path: "major-admin/manage/sc-user",
            component: loadMajorAdmin("ManageScUser"),
            name: "ManageScUser",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: true,
              isTcAdmin: false,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path: "major-admin/manage/student",
            component: loadMajorAdmin("MajorAdminManageStudent"),
            name: "MajorAdminManageStudent",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: true,
              isTcAdmin: false,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path:
              "major-admin/manage/generations/sessions/:id&:major_id&:name_en",
            component: loadMajorAdmin("ManageSession"),
            name: "ManageSession",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: true,
              isTcAdmin: false,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path:
              "major-admin/manage/generations/sessions/subjects/:id&:name_en&:gen_id",
            component: loadMajorAdmin("ManageSubject"),
            name: "ManageSubject",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: true,
              isTcAdmin: false,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path:
              "major-admin/manage/generations/sessions/subjects/score/:id&:session_id&:name",
            component: loadMajorAdmin("ManageScore"),
            name: "ManageScore",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: true,
              isTcAdmin: false,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path:
              "major-admin/manage/generations/sessions/session-score/:sess_id",
            component: loadMajorAdmin("ManageSessionScore"),
            name: "ManageSessionScore",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: true,
              isTcAdmin: false,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path: "major-admin/manage/lecturers",
            component: loadMajorAdmin("MajorAdminManageLecturer"),
            name: "MajorAdminManageLecturer",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: true,
              isTcAdmin: false,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path: "major-admin/manage/academic-years",
            component: loadMajorAdmin("ManageAcademicYear"),
            name: "ManageAcademicYear",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: true,
              isTcAdmin: false,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path: "major-admin/annual/gen",
            component: loadMajorAdmin("AnnualGen"),
            name: "AnnualGen",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: true,
              isTcAdmin: false,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path: "major-admin/annual/gen/score/:id&:name_en&:major_id",
            component: loadMajorAdmin("AnnualScore"),
            name: "AnnualScore",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: true,
              isTcAdmin: false,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path:
              "major-admin/annual/gen/score/studentscore/:session_ids&:annual_id",
            component: loadMajorAdmin("MajorAdminAnnualScore"),
            name: "MajorAdminAnnualScore",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: true,
              isTcAdmin: false,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path:
              "major-admin/annual/gen/score/studentscore/transcript/:student_id&:annual_id",
            component: loadMajorAdmin("MajorAdminTranscript"),
            name: "MajorAdminTranscript",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: true,
              isTcAdmin: false,
              isTopManager: false,
              roleChoose: false
            }
          },

          {
            path: "tscc",
            component: loadTsc("Dashboard"),
            name: "TscDashboard",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: false,
              isTcAdmin: true,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path: "tsc/manage/annual-score",
            component: loadTsc("ManageAnnual"),
            name: "ManageAnnual",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: false,
              isTcAdmin: true,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path: "tsc/manage/annual-score/generation/:id&:name_en",
            component: loadTsc("ManageGen"),
            name: "ManageGen",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: false,
              isTcAdmin: true,
              isTopManager: false,
              roleChoose: false
            }
          },

          {
            path:
              "tsc/manage/annual-score/generation/annual/:id&:name_en&:major_id",
            component: loadTsc("ManageAnnualScore"),
            name: "ManageAnnualScore",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: false,
              isTcAdmin: true,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path:
              "tsc/manage/annual-score/generation/annual/score/:session_ids&:annual_id",
            component: loadTsc("GenerateAnnual"),
            name: "GenerateAnnual",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: false,
              isTcAdmin: true,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path:
              "tsc/manage/annual-score/generation/annual/score/transcript/:student_id&:annual_id",
            component: loadTsc("Transcript"),
            name: "Transcript",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: false,
              isTcAdmin: true,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path: "tsc/manage/user-tsc",
            component: loadTsc("ManageTsc"),
            name: "ManageTsc",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: false,
              isTcAdmin: true,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path: "tsc/manage/lecturer",
            component: loadTsc("TscManageLecturer"),
            name: "TscManageLecturer",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: false,
              isTcAdmin: true,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path: "tsc/manage/student",
            component: loadTsc("ManageStudent"),
            name: "ManageStudentByTSC",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: false,
              isTcAdmin: true,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path: "tsc/manage/generate-transcript",
            component: loadTsc("GenerateTranscript"),
            name: "GenerateTranscript",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: false,
              isMajorAdmin: false,
              isTcAdmin: true,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path: "lecturerr",
            component: loadLecturer("Dashboard"),
            name: "LecturerDashboard",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: true,
              isStudent: false,
              isMajorAdmin: false,
              isTcAdmin: false,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path: "studentt",
            component: loadStudent("Dashboard"),
            name: "StudentDashboards",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: true,
              isMajorAdmin: false,
              isTcAdmin: false,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path: "studentt/session/:student_id&:name_en&:major_id",
            component: loadStudent("SessionStudent"),
            name: "SessionStudent",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: true,
              isMajorAdmin: false,
              isTcAdmin: false,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path: "studentt/session/subject/:id&:name_en&:student_id",
            component: loadStudent("SubjectStudent"),
            name: "SubjectStudent",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: true,
              isMajorAdmin: false,
              isTcAdmin: false,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path: "studentt/session/subject/session-score/:sess_id&:student_id",
            component: loadStudent("SessionScoreStudent"),
            name: "SessionScoreStudent",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: true,
              isMajorAdmin: false,
              isTcAdmin: false,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path: "studentt/session/subject/score/:id&:name&:student_id",
            component: loadStudent("ScoreStudent"),
            name: "ScoreStudent",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: true,
              isMajorAdmin: false,
              isTcAdmin: false,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path: "student/annual",
            component: loadStudent("StudentAnnual"),
            name: "StudentAnnual",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: true,
              isMajorAdmin: false,
              isTcAdmin: false,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path: "student/annual/score/:stu_id&:annual_id",
            component: loadStudent("StudentAnnualScore"),
            name: "StudentAnnualScore",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: true,
              isMajorAdmin: false,
              isTcAdmin: false,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path: "student/annual/score/transcript/:student_id&:annual_id",
            component: loadStudent("StudentTranscript"),
            name: "StudentTranscript",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: false,
              isStudent: true,
              isMajorAdmin: false,
              isTcAdmin: false,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path: "lecturer/scores",
            component: loadLecturer("Session"),
            name: "Session",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: true,
              isStudent: false,
              isMajorAdmin: false,
              isTcAdmin: false,
              isTopManager: false,
              roleChoose: false
            }
          },
          {
            path: "lecturer/scores/points/:id&:name",
            component: loadLecturer("Score"),
            name: "Score",
            meta: {
              requireAuth: true,
              guest: false,
              isAdmin: false,
              isLecturer: true,
              isStudent: false,
              isMajorAdmin: false,
              isTcAdmin: false,
              isTopManager: false,
              roleChoose: false
            }
          }
        ]
      },
      {
        path: "signin",
        component: load("auth/SignIn"),
        name: "SignIn",
        meta: {
          guest: true
        }
      },
      {
        path: "roledashboard",
        component: load("auth/RoleDashboard"),
        name: "RoleDashboard",
        meta: {
          guest: false
        }
      },
      {
        path: "anonymous",
        component: load("auth/Error404"),
        name: "Anonymous",
        meta: {
          guest: true
        }
      }
    ]
  },
  {
    path: "*",
    redirect() {
      return Trans.defaultLocale;
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
}
});

router.beforeEach((to, from, next) => {
  // Redirect to route
  let redirectToRoute = function (name) {
    if (name === from.name) {
      next();
      return;
    }

    next({ name: name, params: { locale: i18n.locale } });
  };

  // Get logged user
  let loggedUser = store.getters.getLoggedUser;

  if (to.meta.requireAuth) {
    if (loggedUser && loggedUser.payload.roles.length > 1) {
      return redirectToRoute("RoleDashboard");
    } else {
      if (!loggedUser || !loggedUser.payload.token) {
        return redirectToRoute("SignIn");
      } else if (to.meta.isAdmin) {
        try {
          if (loggedUser.payload.roles[0].value == "super_admin") {
            next();
          } else {
            return redirectToRoute("ManageUser");
          }
        } catch (e) {
          window.alert("Error: Permission Denied");
        }
      } else if (to.meta.isLecturer) {
        try {
          if (loggedUser.payload.roles[0].value == "lecturer") {
            next();
          } else {
            return redirectToRoute("Session");
          }
        } catch (e) {
          window.alert("Error: Permission Denied");
        }
      } else if (to.meta.isStudent) {
        try {
          if (loggedUser.payload.roles[0].value == "student") {
            next();
          } else {
            return redirectToRoute("StudentDashboards");
          }
        } catch (e) {
          window.alert("Error: Permission Denied");
        }
      } else if (to.meta.isMajorAdmin) {
        try {
          if (loggedUser.payload.roles[0].value.includes("major_admin")) {
            next();
          } else {
            return redirectToRoute("ManageMajorGen");
          }
        } catch (e) {
          window.alert("Error: Permission Denied");
        }
      } else if (to.meta.isTcAdmin) {
        try {
          if (loggedUser.payload.roles[0].value == "tsc") {
            next();
          } else {
            return redirectToRoute("TscDashboard");
          }
        } catch (e) {
          window.alert("Error: Permission Denied");
        }
      } else if (to.meta.isTopManager) {
        try {
          if (loggedUser.payload.roles[0].value == "top_manager") {
            next();
          } else {
            return redirectToRoute("TopManagerDashboard");
          }
        } catch (e) {
          window.alert("Error: Permission Denied");
        }
      } else {
        return next();
      }
    }
  }

  if (to.meta.guest) {
    if (loggedUser)
      if (loggedUser.payload.roles.length > 1) {
        return redirectToRoute("RoleDashboard");
      } else {
        if (loggedUser.payload.roles[0].value == "super_admin") {
          return redirectToRoute("ManageUser");
        } else if (loggedUser.payload.roles[0].value == "lecturer") {
          return redirectToRoute("Session");
        } else if (loggedUser.payload.roles[0].value == "student") {
          return redirectToRoute("StudentDashboards");
        } else if (loggedUser.payload.roles[0].value.includes("major_admin")) {
          return redirectToRoute("ManageMajorGen");
        } else if (loggedUser.payload.roles[0].value == "tsc") {
          return redirectToRoute("TscDashboard");
        } else if (loggedUser.payload.roles[0].value == "top_manager") {
          return redirectToRoute("TopManagerDashboard");
        } else return next();
      }
  }

  next();
});

// Get logged user

export default router;
