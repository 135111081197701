const DISPLAY_LOADER = (state, display) => {
  state.displayLoader = display;
};

const LOGGED_USER = (state, user) => {
  // let now = new Date()
  // let expiryDate = new Date()
  // user.expiryDate = expiryDate.setTime(now.getTime() + user.expires_in * 1000)

  localStorage.setItem("loggedUser", JSON.stringify(user));
  state.loggedUser = JSON.stringify(user);
};

const LOGGED_MAJOR = (state, major) => {
  localStorage.setItem("loggedMajor", JSON.stringify(major));
  state.loggedMajor = JSON.stringify(major);
};

const LOGGED_TOKEN = (state, token) => {
  localStorage.setItem("loggedToken", JSON.stringify(token));
  state.loggedToken = JSON.stringify(token);
};

const REMOVE_LOGGED_USER = state => {
  state.loggedUser = null;
  state.loggedMajor = null;
  state.loggedToken = null;
  localStorage.removeItem("loggedUser");
  localStorage.removeItem("loggedMajor");
  localStorage.removeItem("loggedToken");
};

export default {
  DISPLAY_LOADER,
  LOGGED_USER,
  REMOVE_LOGGED_USER,
  LOGGED_MAJOR,
  LOGGED_TOKEN
};
